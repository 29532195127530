<script>
import Loading from "vue-loading-overlay";

export default {
  components:{
    Loading
  },
  props: {
    bookingID: Number,
    bookingArtTypes: Array,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      art_type: {},
      booking_art_types: this.bookingArtTypes || [],
      genre_slugs: [],
      genres: [],
      art_type_data: {},
      filteredGenres: [],
      isSearchFilterActive: false,
      current_index: 0,
      hideNextBtn: true
    }
  },
  mounted() {
    this.isLoading = true
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
        .then(response => {
          this.booking_art_types = response.data.booking_art_types.filter(art_type => art_type.slug === 'musicians' || art_type.slug === 'djs')
          this.art_type = response.data.booking_art_types.filter(art_type => art_type.slug === 'musicians' || art_type.slug === 'djs')[0]
          this.current_index = 0
          response.data.booking_art_types.filter(art_type => art_type.slug === 'musicians' || art_type.slug === 'djs').forEach(art_slug => {
            this.art_type_data = {
              ...this.art_type_data,
              [art_slug.slug]: { genre_slugs: art_slug.genres || [] }
            }
          })
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
  },
  watch: {
    art_type (val) {
      this.isLoading = true

      this.$axios.get(`/v3/art_types/${val.slug}/genres`)
          .then(response => {
            this.genres = response.data
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
          })
      this.updateShowNextBtn()
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  methods: {
    isGenreSelected(selected_genre) {
      return this.art_type_data[this.art_type.slug].genre_slugs.findIndex(genre => genre.slug === selected_genre.slug) >= 0;
    },
    genreDeselected(deselected_genre) {
      let genreIndex = this.art_type_data[this.art_type.slug].genre_slugs.findIndex(art_type => art_type.slug === deselected_genre.slug);
      if (genreIndex >= 0) {
        this.art_type_data[this.art_type.slug].genre_slugs.splice(genreIndex, 1)
        this.updateShowNextBtn()
        return true;
      }
      return false
    },
    genreSelected(selected_genre) {
      if (this.genreDeselected(selected_genre)) {
        return;
      } else {
        this.art_type_data[this.art_type.slug].genre_slugs.unshift(selected_genre);
      }
      this.updateShowNextBtn()
    },
    bookingParams() {
      return {
        booking: {
          booking_art_types_attributes: this.booking_art_types.map(art_type => {
            return {
              id: art_type.id,
              genre_slugs: this.art_type_data[art_type.slug].genre_slugs.map(genre => genre.slug),
              is_skip_speciality: this.art_type_data[art_type.slug].is_skip_speciality
            }
          })
        }
      }
    },
    updateStep() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_date'})
    },
    nextArtType() {
      let old_index = this.current_index
      this.current_index = old_index + 1
      this.art_type = this.booking_art_types[old_index + 1]
    },
    previousArtType() {
      let old_index = this.current_index
      this.current_index = old_index - 1
      this.art_type = this.booking_art_types[old_index - 1]
    },
    back() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_speciality'})
    },
    searchGenres(event) {
      const searchTerm = event.target.value.toLowerCase()
      if (searchTerm !== '') {
        this.filteredGenres = this.genres.filter(genre => genre.name.toLowerCase().includes(searchTerm))
        this.isSearchFilterActive = true
      } else {
        this.filteredGenres = []
        this.isSearchFilterActive = false
      }
    },
    updateShowNextBtn() {
      this.hideNextBtn = !(this.art_type_data[this.art_type.slug].genre_slugs.length > 0)
    }
  }
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
       color="#FF8141">
      </loading>
    </div>

    <div class="complete-signup-header__title w-100 mt-3">
      What
      <span
        class='complete-signup-header__primary_title'>
      genres
      </span>
      are you looking for?
    </div>

    <p class="mt-4 mb-5">
      Select the genres you want the artist to perform
    </p>

    <div v-if="art_type_data[art_type.slug] && art_type_data[art_type.slug].genre_slugs.length > 0" class="mb-2">
      <h6 class="mb-3">Selected Genres</h6>
      <ul class="selected-genres-container">
        <li
          class="selected-genre-item"
          v-for="(genre, index) in art_type_data[art_type.slug].genre_slugs"
          :key="index"
          @click="genreDeselected(genre)">
          {{ genre.name }}
          <span class="ms-auto">
            <font-awesome-icon :icon="['fas', 'circle-xmark']" style="color: #ff6161;" />
          </span>
        </li>
      </ul>
    </div>

    <div v-if="genres.length > 0">
      <p class="mb-1">Genres</p>

      <input
        @keyup="searchGenres"
        type="text"
        class="input order-capture-input border-0 mb-5"
        placeholder="Search" >

      <ul v-if="isSearchFilterActive" class="genres-container">
        <li
          class="genre-item ms-2"
          v-for="(genre, index) in filteredGenres"
          v-if="!isGenreSelected(genre)"
          :key="index"
          @click="genreSelected(genre)">
          {{ genre.name }}
        </li>
      </ul>

      <ul v-else class="genres-container">
        <li
          class="genre-item ms-2"
          v-for="(genre, index) in genres"
          v-if="!isGenreSelected(genre)"
          :key="index"
          @click="genreSelected(genre)">
          {{ genre.name }}
        </li>
      </ul>
    </div>

    <div class="mt-5">
      <div class="back-next">
        <button
            v-if="current_index > 0"
            class="settings-forms__btn btn secondary fluid"
            @click="previousArtType()">
          Back
        </button>
        <button
            v-if="current_index === 0"
            class="settings-forms__btn btn secondary fluid"
            @click="back()">
          Back
        </button>
        <button
            v-if="current_index < booking_art_types.length - 1"
            class="settings-forms__btn btn primary fluid"
            @click="nextArtType()"
            :disabled="hideNextBtn">
          Next
        </button>
        <button
            v-if="current_index === booking_art_types.length - 1"
            class="settings-forms__btn btn primary fluid"
            @click="updateStep()"
            :disabled="hideNextBtn">
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>