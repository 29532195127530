<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading,
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      art_types: [],
      art_type: {},
      error: null,
      booking_applications: [],
      is_guest: false,
      current_index: 0,
      booking_type: 'gig'
    }
  },
  watch: {
  },
  mounted() {
    this.isLoading = true
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        this.art_types = response.data.booking_art_types
        this.art_type = response.data.booking_art_types[0]
        this.is_guest = response.data.client.is_guest? false : true
        this.booking_type = response.data.booking_applications.length > 0 ? 'direct': 'gig'
        this.current_index = 0
        this.booking_applications = response.data.booking_applications
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })
    this.$refs.description.focus()
  },
  methods: {
    bookingParams() {
      return {
        booking: {
          booking_type: this.booking_type,
          booking_art_types_attributes: this.art_types.map(art_type => {
            return {
              id: art_type.id,
              description: art_type.description,
            }
          })
        }
      }
    },
    nextStep() {
      if (this.is_guest) {
        this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'fill_name' })
      } else if (this.booking_applications.length > 0) {
        this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_booking_type' })
      } else {
        this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'booking_complete' })
      }
    },
    nextArtType() {
      let old_index = this.current_index
      this.current_index = old_index + 1
      this.art_type = this.art_types[old_index + 1]
    },
    previousArtType() {
      let old_index = this.current_index
      this.current_index = old_index - 1
      this.art_type = this.art_types[old_index - 1]
    },
    back() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_budget' })
    },
  },
  computed: {
    disableNextBtn() {
      return this.art_types.some(art_type => !art_type.description || art_type.description.trim().length < 50)
    },
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <div class="complete-signup-header__title">
      What should the
      <span class='complete-signup-header__primary_title'>
        {{ art_type.name }}
      </span>
      know about the event?
    </div>

    <p class="mt-4 mb-5">
      Provide the artists with as much detail as possible about your event, such as venue requirements, theme, dress code, or absolutely anything at all.
    </p>

    <div class="new-booking__content">
      <p class="mb-3">Event details</p>
      <div class="forms__item-100">
        <div>
          <textarea
            v-model="art_type.description"
            type="textarea"
            :required="true"
            class="textarea order-capture-input border-0"
            v-bind:class="{ 'error': error || (art_type.description && art_type.description.trim().length < 50)}"
            placeholder="Anything you want the artist to know?"
            rows="9"
            ref="description"
          />
          <span v-if="error" class="error-message">{{ error? error.toString() : null }}</span>
          <span v-if="art_type.description && art_type.description.trim().length < 50" class="error-message">Minimum of 50 characters required</span>
        </div>
      </div>

      <div class="mt-5">
        <div class="back-next">
          <button
            v-if="current_index > 0"
            class="settings-forms__btn btn secondary fluid"
            @click="previousArtType()">
            Back
          </button>
          <button
            v-if="current_index === 0"
            class="settings-forms__btn btn secondary fluid"
            @click="back()">
            Back
          </button>
          <button
            v-if="current_index < art_types.length - 1"
            class="settings-forms__btn btn primary fluid"
            @click="nextArtType()"
            :disabled="!art_type.description || art_type.description.trim().length < 50">
            Next
          </button>
          <button
            v-if="current_index === art_types.length - 1"
            class="settings-forms__btn btn primary fluid"
            @click="nextStep()"
            :disabled="disableNextBtn">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
