<script>

import * as _ from 'lodash';
import moment from 'moment';

import BookingProgress from "./new_booking/BookingProgress.vue";
import SelectArtistType from "./new_booking/SelectArtistType.vue";
import SelectSpeciality from "./new_booking/SelectSpeciality.vue";
import SelectGenre from "./new_booking/SelectGenre.vue";
import SelectDate from "./new_booking/SelectDate.vue";
import SelectLocation from "./new_booking/SelectLocation.vue";
import SelectTime from "./new_booking/SelectTime.vue";
import SelectDuration from "./new_booking/SelectDuration.vue";
import SelectEventType from "./new_booking/SelectEventType.vue";
import SelectGuest from "./new_booking/SelectGuest.vue";
import SelectBudget from "./new_booking/SelectBudget.vue";
import FillDescription from "./new_booking/FillDescription.vue";
import SelectBookingType from "./new_booking/SelectBookingType.vue";
import MessageInfo from "./new_booking/MessageInfo.vue";

import FillName from "./new_booking/FillName.vue";
import FillEmail from "./new_booking/FillEmail.vue";
import FillPhone from "./new_booking/FillPhone.vue";
import FillPassword from "./new_booking/FillPassword.vue";

export default {
  components: {
    BookingProgress,
    SelectArtistType,
    SelectSpeciality,
    SelectGenre,
    SelectDate,
    SelectLocation,
    SelectTime,
    SelectDuration,
    SelectEventType,
    SelectGuest,
    SelectBudget,
    FillDescription,
    SelectBookingType,
    FillName,
    FillEmail,
    FillPhone,
    FillPassword,
    MessageInfo,
  },
  props: {},
  data() {
    return {
      phoneRegex: /[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}/im,
      emailRegex: /(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/i,
      urlRegex: /((https?:\/\/|www.?)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/i,
      moment: moment,
      is_guest: false,
      isShowSelectGenreScreen: true,
      step: localStorage.getItem("booking_step") || "select_artist_type",
      bookingID: null,
      booking: {},
      booking_applications: [],
      isMessageSelected: JSON.parse(localStorage.getItem('isMessageSelected', 'false')),
      guestToken: null,
    };
  },
  mounted() {
    let token = localStorage.getItem("token", "");
    this.guestToken = localStorage.getItem("guest_token", "");
    localStorage.setItem("pauseTime", true);
    if(this.guestToken) {
      this.$axios.defaults.headers.common["Authorization"] = token;
      this.getProfile();
      this.getGuestBooking()
    }
    else if (token === null) {
      this.$axios.get("/api_tokens/new.json").then((response) => {
        localStorage.setItem("token", response.data.jwt);
        this.$axios.defaults.headers.common["Authorization"] =
            response.data.jwt;
        this.getProfile();
        this.getBooking();
      });
    } else {
      this.$axios.defaults.headers.common["Authorization"] = token;
      this.getProfile();
      this.getBooking();
    }
  },
  methods: {
    redactedBookingDescription(artTypeDescription) {
      let descriptionContent = artTypeDescription

      let phone = descriptionContent.match(this.phoneRegex)
      if(phone) {
        let descriptionContentChunks = descriptionContent.split(phone[0])
        descriptionContent = `${descriptionContentChunks[0]} ********* ${descriptionContentChunks[1]}`
      }

      let email = descriptionContent.match(this.emailRegex)
      if(email) {
        let descriptionContentChunks = descriptionContent.split(email[0])
        descriptionContent = `${descriptionContentChunks[0]} ********* ${descriptionContentChunks[1]}`
      }

      let url = descriptionContent.match(this.urlRegex)
      if(url) {
        let descriptionContentChunks = descriptionContent.split(url[0])
        descriptionContent = `${descriptionContentChunks[0]} ********* ${descriptionContentChunks[1]}`
      }

      return descriptionContent
    },
    getGuestBooking() {
      this.$axios.get("/v3/event_bookings/new", { headers: {
          Authorization: localStorage.getItem('guest_token', ''),
        }
      }).then((response)=> {
        this.booking = response.data;
        this.$axios.put(`/v3/event_bookings/${response.data.id}/transfer_booking`)
        .then(response => {
          this.getBooking()
          localStorage.removeItem("guest_token");
        })
      })
    },
    durationLabel (title) {
      let time = title.split(':')
      let hour = time[0] === '0' ? '' : (time[0] === '1' ? '1 hour' : `${time[0]} hours`)
      let min = time[1] === '00' ? '' : ` ${time[1]} minutes`
      return hour + min
    },
    getProfile() {
      this.$axios.get('/v3/users/profile')
          .then(response => {
            this.is_guest = response.data.role === 'guest'
          })
    },
    shouldShowGenres(booking_art_types) {
      return booking_art_types.some(art_type => {
        return art_type.slug === "musicians" || art_type.slug === "djs";
      });
    },
    getBooking() {
      this.$axios.get("/v3/event_bookings/new").then((response) => {
        const url = new URL(window.location.href);
        let instant_quote = url.pathname.includes("instant_quote")
        let artistInUrl = url.pathname.includes("artist")
        let aiArtistInUrl = url.pathname.includes("ai")
        let bookingStep = ''
        if (this.guestToken) {
          this.bookingID = this.booking.id;
          this.booking_applications = this.booking.booking_applications;
        } else {
          this.booking = response.data;
          if (!instant_quote && !artistInUrl && !aiArtistInUrl) {
            const bookingUrl = `${window.location.origin}/instant_quote`
            window.history.pushState({ path: bookingUrl }, '', bookingUrl)
          }
          this.bookingID = this.booking.id;
          this.booking_applications = response.data.booking_applications;
        }
        if (this.booking.booking_art_types.length <= 0) {
          this.updateStep({ next_step: "select_artist_type"});
        } else {
          if (this.guestToken) {
            this.updateStep({
              next_step: this.booking.booking_type== "gig" ? "booking_complete" : "select_booking_type"
            })
          }
          this.isShowSelectGenreScreen = !(_.find(this.booking.booking_art_types, (art_type) => art_type.slug === 'musicians' || art_type.slug === 'djs') == undefined)
        }
      });
    },
    updateStep(params) {
      this.isLoading = true
      if (params.booking_params) {
        this.$axios.put(`/v3/event_bookings/${this.bookingID}`, params.booking_params)
            .then(response => {
              this.booking = response.data
              this.booking_applications = this.booking.booking_applications;
              this.isShowSelectGenreScreen = !(_.find(response.data.booking_art_types, (art_type) => art_type.slug === 'musicians' || art_type.slug === 'djs') == undefined)
              this.saveCurrentStep(params.next_step)
              if (params.next_step === 'booking_complete') this.completeBooking()
              window.initMap()
              this.isLoading = false
            })
            .catch(error => {
              this.isLoading = false
            })
      } else {
        if (params.next_step === 'booking_complete') { 
          window.initMap()
          this.completeBooking()
        }
        this.saveCurrentStep(params.next_step)
      }
    },
    completeBooking() {
      this.$axios.put(`/v3/event_bookings/${this.bookingID}/complete`)
          .then(response => {
          })
    },
    saveCurrentStep(current_step) {
      if (current_step === "done" || current_step === "booking_complete") {
        localStorage.removeItem("booking_step");
      } else {
        localStorage.setItem("booking_step", current_step);
        let artistInUrl = window.location.href.includes("artist")
        if (!artistInUrl){
          const bookingUrl = `${window.location.origin}/instant_quote`
          window.history.pushState({ path: bookingUrl }, '', bookingUrl)
        }
      }
      this.step = current_step;
      window.scroll({ top: 0, left: 0 });
    },
    back() {
      this.isLoading = true;
      let params = {
        booking: {
          status: "pending",
        },
      };
      this.updateStep({
        booking_params: params,
        next_step: this.booking_applications.length > 0
            ? "select_booking_type"
            : "fill_description"
      })
    },
    complete() {
      this.updateStep({ next_step: "done"});
      window.location.href = `/event_bookings/${this.bookingID}`;
    },
    goToMessenger() {
      this.updateStep({ next_step: "done"});
      window.location.href = '/messenger';
    }
  },
  computed: {
    activeStepNum() {
      let stepNumbers = {
        select_artist_type: 1,
        select_speciality: 2,
        select_genre: 3,
        select_date: 4,
        select_location: 5,
        select_time: 6,
        select_duration: 7,
        select_event_type: 8,
        select_guest: 9,
        select_budget: 10,
        fill_description: 11
      }

      const guestSteps = {
        fill_name: 12,
        fill_phone: 13,
        fill_email: 14,
        fill_password: 15,
        select_booking_type: 16,
        booking_complete: 17
      }

      const regularUserSteps = {
        select_booking_type: 12,
        booking_complete: 13
      }

      if (this.is_guest) {
        stepNumbers = { ...stepNumbers, ...guestSteps }
      } else {
        stepNumbers = { ...stepNumbers, ...regularUserSteps }
      }

      let activeStep = stepNumbers[this.step]
      if (activeStep > 2 && !this.isShowSelectGenreScreen) activeStep--
      if (activeStep > 11 && this.booking_applications.length <= 0) activeStep--
      return activeStep
    },
    totalSteps() {
      let actualSteps = this.is_guest ? 17 : 13
      if (!this.isShowSelectGenreScreen) actualSteps--
      if (this.booking_applications.length <= 0) actualSteps--
      return actualSteps
    }
  },
  beforeDestroy() {
    localStorage.removeItem("pauseTime");
  }
};
</script>

<template>
  <div class="new-booking">
    <booking-progress :step="step" v-if="bookingID">

      <message-info
          :bookingID="bookingID"
          v-if="step === 'message_info'"
          @updateStep="updateStep"
      />

      <select-artist-type
          :step="step"
          :artTypes="booking.art_types"
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_artist_type'"
      />

      <select-speciality
          :bookingID="bookingID"
          :bookingArtTypes="booking.booking_art_types"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_speciality'"
      />

      <select-genre
          :bookingID="bookingID"
          :bookingArtTypes="booking.booking_art_types.filter(art_type => art_type.slug === 'musicians' || art_type.slug === 'djs')"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_genre'"
      />

      <select-date
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_date'"
      />

      <select-location
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_location'"
      />

      <select-time
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_time'"
      />

      <select-duration
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_duration'"
      />

      <select-event-type
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_event_type'"
      />

      <select-guest
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_guest'"
      />

      <select-budget
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_budget'"
      />

      <fill-description
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'fill_description'"
      />

      <fill-name
          :activeStep="activeStepNum"
          :isClassBooking="false"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'fill_name'"
      />

      <fill-email
          v-if="step === 'fill_email'"
          :isClassBooking="false"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
      />

      <fill-phone
          v-if="step === 'fill_phone'"
          :isClassBooking="false"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
      />

      <fill-password
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'fill_password'"
      />

      <select-booking-type
          :bookingID="bookingID"
          :activeStep="activeStepNum"
          :totalSteps="totalSteps"
          @updateStep="updateStep"
          v-if="step === 'select_booking_type'"
      />

      <div v-if="step === 'booking_complete' || step === 'done'">
        <h4>
          Congratulations!
          <span>
            <font-awesome-icon :icon="['fas', 'circle-check']" style="color: #ff8141;" />
          </span>
        </h4>

        <p class="mt-4 mb-4">
          Your request has been sent!
        </p>

        <p class="mb-5">
          Once the booking is accepted, you will be notified when artists start quoting for your event
        </p>

        <h4 class="mb-4">Booking request summary</h4>

        <div class="new-booking__review-content">
          <h5 class="my-3">Description</h5>
          <div v-for="art_type in booking.booking_art_types">
            <p class="booking-artist-detail__description text-break">{{ redactedBookingDescription(art_type.description) }}</p>
          </div>

          <div class="mt-3">
            <div class="d-flex justify-content-between">
              <div class="col-6 mx-0">
                <h5 class="mb-0">{{ booking.booking_dates.length > 1 ? 'Start date' : 'Event date' }}</h5>
                {{ moment.utc(booking.booking_dates[0].date).format('DD MMM YYYY') }}
              </div>
              <div v-if="booking.booking_dates.length > 1" class="col-6 mx-0">
                <h5 class="mb-0">End date</h5>
                {{ moment.utc(booking.booking_dates[booking.booking_dates.length - 1].date).format('DD MMM YYYY') }}
              </div>
            </div>

            <div class="d-flex mt-1">
              <div class="col-6 mx-0">
                <h5 class="mb-0">Start time</h5>
                {{ moment.utc(booking.start_time).format('LT') }}
              </div>
              <div class="col-6 mx-0">
                <h5 class="mb-0">End time</h5>
                {{ moment.utc(booking.end_time).format('LT') }}
              </div>
            </div>
          </div>

          <div class="mt-1 row">
            <div class="col-12 mx-0 py-0">
              <h5 class="mb-0">Requested artists</h5>
            </div>
            <div class="col-12 mx-0 py-0">
              {{ booking.booking_art_types.map(art_type => art_type.name).join(', ') }}
            </div>
          </div>
          <div v-if="shouldShowGenres(booking.booking_art_types)" class="col-12 mt-3">
            <div class="col-12 mx-0">
              <h5 class="mb-0">Genres</h5>
            </div>
            <div class="col-12 mx-0">
              {{ booking.booking_art_types.map(art_type => art_type.genres.map(genre => genre.name).join(', ')).join(', ') }}
            </div>
          </div>

          <div class="d-flex mt-3">
            <div class="col-6 mx-0">
              <h5 class="mb-0">Duration</h5>
              {{ booking.booking_art_types.map(art_type => art_type.duration? durationLabel(art_type.duration) : '' ).join(', ') }}
            </div>
            <div class="col-6 mx-0">
              <h5 class="mb-0">Guests</h5>
              {{ booking.guests }}
            </div>
          </div>

          <div class="d-flex mt-1">
            <div class="col-6 mx-0">
              <h5 class="mb-0">Booking reference</h5>
              #{{ booking.id }}
            </div>
            <div class="col-6 mx-0">
              <h5 class="mb-0">Total Budget</h5>
              {{booking.budget ? booking.currency.slug.toUpperCase() : ''}} {{ booking.budget ? booking.budget : 'N/A'  }}
            </div>
          </div>

          <div v-if="shouldShowGenres(booking.booking_art_types)" class="mt-3 mb-3">
            <h5 class="mb-0">Sound System</h5>
            {{ booking.sound_equipment ? 'Not available at the venue' : 'Available at the venue' }}
          </div>

          <div class="mt-3 mb-3">
            <h5 class="mb-0">Location</h5>
            {{ booking.location }}<br/>
            {{ booking.address }}
          </div>

          <div
              v-if="booking.latitude && booking.longitude"
              class="mt-2 mb-4 google_map"
              :data-latitude="`${parseFloat(booking.latitude)}`"
              :data-longitude="`${parseFloat(booking.longitude)}`"
              style="height: 165px;">
          </div>
        </div>

        <div class="mt-3">
          <v-app class="w-100 mb-4">
            <p class="mb-1">Step {{ activeStepNum }} of {{ totalSteps }}</p>
            <v-progress-linear
                rounded
                :value="activeStepNum * 100 / totalSteps"
                height="8">
            </v-progress-linear>
          </v-app>

          <div v-if="isMessageSelected" class="back-next">
            <button
                class="settings-forms__btn btn secondary fluid"
                @click="complete"
                :disabled="step === 'done'"
            >
              Review my event
            </button>
            <button
                class="settings-forms__btn btn primary fluid"
                @click="goToMessenger"
                :disabled="step === 'done'"
            >
              Go to Messenger
            </button>
          </div>

          <div v-else class="back-next">
            <button
                class="settings-forms__btn me-0 btn primary fluid"
                @click="complete"
                :disabled="step === 'done'">
              Review my event
            </button>
          </div>
        </div>
      </div>
    </booking-progress>
  </div>
</template>
<style lang="scss" scoped></style>
