<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import logo from '../../../images/logo.svg'

export default {
  components:{
    Loading
  },
  props: {
    step: String,
    artTypes: Array,
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      art_type: [],
      booking_art_types: [],
      art_types: [],
      selectedTypes: [],
      logo: logo
    }
  },
  watch: {
    artTypes (val) {
      if (val) {
        this.art_type = val
      }
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  mounted() {
    this.isLoading = true
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        this.booking_art_types = response.data.booking_art_types
        this.art_type = response.data.art_types
        this.selectedTypes = response.data.art_types.map((type) => type.slug);
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })

    this.$axios.get('/v3/art_types')
      .then(response => {
        this.art_types = response.data
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })
  },
  methods: {
    toggleSelection(artType) {
      const index = this.selectedTypes.indexOf(artType.slug);
      if (index === -1) {
        this.selectedTypes.push(artType.slug);
        this.art_type.push(artType);
      } else {
        this.selectedTypes.splice(index, 1);
        this.art_type.splice(index, 1);
      }
    },
    nextStep() {
      let new_art_types = this.art_type.map(new_art_type => {
        let index = this.booking_art_types.findIndex(booking_art_type => booking_art_type.slug == new_art_type.slug)
        return {
          id: index >=0 ? this.booking_art_types[index].id : null,
          art_type_slug: new_art_type.slug,
        }
      })

      let removed_art_types = this.booking_art_types.map(booking_art_type => {
        let index = this.art_type.findIndex(new_art_type => new_art_type.slug == booking_art_type.slug)
        if (index < 0) {
          return {
            id: booking_art_type.id,
            art_type_slug: booking_art_type.slug,
            _destroy: true
          }
        }
      }).filter(item => item)
      let params = {
        booking:  {
          booking_art_types_attributes: new_art_types.concat(removed_art_types)
        }
      }

      this.$emit('updateStep', { booking_params: params, next_step: 'select_speciality' })
    }
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <p class="mt-4" style="margin-bottom: 30px">
      Select which art types you are looking for
    </p>

    <button class="btn search-with-ai">
      <a href="/ai">
        <span><img :src="logo" class="me-2 d-flex" /></span>
        <span> Book with Soul Artists AI </span>
      </a>
    </button>

    <div class="art_types-container">
      <p class="mb-3">Selected Art Types</p>
      <div class="btn-group" role="group">
        <button
          v-for="(type, index) in art_types"
          :key="index"
          class="btns"
          :class="selectedTypes.includes(type.slug) ? 'selected-art-type-item' : 'art-type-item'"
          @click="toggleSelection(type)"
          >
          <img
            v-if="type.icon.small"
            :src="type.icon.small"
            alt="icon"
            class="art-type-icon"
          />
          {{ type.name }}
        </button>
      </div>
    </div>

    <div class="w-100 mt-5">
      <button class="settings-forms__btn btn primary fluid nxt-btn"
        @click="nextStep"
        :disabled="art_type.length <= 0">
        Next
      </button>
    </div>
  </div>
</template>
<style scoped lang="scss">
.option{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.custom-input-wrap{
  margin-bottom:16px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
