<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading,
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      booking_type: '',
      booking_types: ['direct', 'gig'],
      art_types: [],
      stage_name: '',
      error: null,
      isMessageSelected: JSON.parse(localStorage.getItem('isMessageSelected', 'false'))
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  mounted() {
    this.isLoading = true
    if (JSON.parse(localStorage.getItem('isMessageSelected', 'false'))) {
      this.booking_type = 'direct'
      this.nextStep()
    } else {
      this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
        .then(response => {
          this.booking_type = this.isMessageSelected ? 'direct' : response.data.booking_type
          this.art_types = response.data.booking_art_types.map(art_type => art_type.name)
          this.stage_name = response.data.booking_applications[0].stage_name
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    }
  },
  methods: {
    nextStep() {
      this.isLoading = true
      let params = {
        booking: {
          booking_type: this.booking_type
        }
      }
      this.$emit('updateStep', { booking_params: params, next_step: 'booking_complete' })
    },
    back() {
      let params = {
        booking: {
          booking_type: this.booking_type
        }
      }
      this.$emit('updateStep', { booking_params: params, next_step: 'fill_description' })
    },
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <div v-if="!isMessageSelected">
      <p class="mb-5">
        Your inquiry will also be sent to artists that are similar to
        <span class="cr-primary">{{ stage_name }}</span>
      </p>

      <div class="custom-input-wrap forms__item-wp forms__item-100" >
        <div class="field-selecttype__container">
          <div class="field-selecttype__label w-100"
            :class="booking_type == 'gig' ? 'active': ''"
            @click="booking_type = 'gig'"
          >
            <p class="field-selecttype__recommended">Recommended</p>
            <p class="strong">Yes, please</p>
            <p class="cr-silver field-selecttype__description">I would like to receive quote from other artists</p>
          </div>
        </div>

        <div class="field-selecttype__container mt-3">
          <div class="field-selecttype__label w-100"
            :class="booking_type == 'direct' ? 'active': ''"
            @click="booking_type = 'direct'"
          >
            <p class="strong">No Thanks</p>
            <p class="cr-silver field-selecttype__description">
              I am only interested in hiring
              <span class="cr-primary">{{ stage_name }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="back-next">
        <button
          class="settings-forms__btn btn secondary fluid"
          @click="back()">
          Back
        </button>
        <button
          class="settings-forms__btn btn primary fluid"
          @click="nextStep()"
          :disabled="!['gig', 'direct'].includes(booking_type)">
          Next
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped lang='scss'>
.option{
  display: flex;
  justify-content: flex-start;
align-items: center;
}
.custom-input-wrap{
  margin-bottom:16px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.field-selecttype__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;
}
.field-selecttype__label {
  width: 48%;
  min-height: 100%;
  text-align: center;
  padding: 15px;
  position: relative;
  border: 3px solid transparent;
  box-shadow: 0px 10px 30px #9393931A;

  &:hover {
    border: 3px solid#ff8141;
    border-radius: 5px;
    box-shadow: 0px 10px 30px #9393931A;

    .field-selecttype__recommended {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.active {
    border: 3px solid#ff8141;
    border-radius: 5px;
    box-shadow: 0px 10px 30px #9393931A;

    .field-selecttype__recommended {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  img {
    height: 25px;
  }

  .strong {
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px;
  }

  .field-selecttype__recommended {
    position: absolute;
    top: -1px;
    left: -1px;
    color: #ffffff;
    border-radius: 5px;
    font-size: 12px;
    padding: 0 7px;
    background-color: #FF8141;
  }

  .field-selecttype__description {
    font-size: 12px;
    padding: 10px 25px 0;
  }

  button {
    background-color: #FF8141;
    box-shadow: 0px 3px 6px #0000001A;
    border-radius: 5px;
    padding: 10px 35px;
    color: #ffffff;
    margin-top: 10px;
    outline: none;
    border: none;
    cursor: pointer;
  }

  .field-selecttype__icon-mobile {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .field-selecttype__container {
    flex-direction: column;

    .field-selecttype__label {
      width: 95%;

      &:first-child {
        margin-bottom: 10px;
      }

      &:hover {
        border: 3px solid transparent;
      }

      &.active {
        &:hover {
          border: 3px solid#ff8141;
          border-radius: 5px;
          box-shadow: 0px 10px 30px #9393931A;
        }
      }
    }

    .field-selecttype__icon-desktop {
      display: none;
    }
    .field-selecttype__icon-mobile {
      display: inline-block;
      height: 25px;
    }
  }
}
</style>
