<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { parsePhoneNumberFromString} from 'libphonenumber-js'
const getCountryISO2 = require("country-iso-3-to-2");
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Socials from "../../sign_up/Socials.vue";

export default {
  components:{
    Loading,
    VuePhoneNumberInput,
    Socials
  },
  props: {
    isClassBooking: Boolean,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      error: null,
      isValid: false,
      phone: '',
      countryCode: '',
      formattedNumber: ''
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  mounted() {
    this.isLoading = true
    this.$axios.get('/v3/users/profile').then(response => {
      if (response.data.phone) {
        this.phone = response.data.phone || ''
        const parse = parsePhoneNumberFromString(response.data.phone)
        this.countryCode = parse.country
      } else {
        this.countryCode = getCountryISO2(response.data.country.slug.toUpperCase())
      }
      this.isLoading = false
    })
    .catch(error => {
        this.isLoading = false
      })
  },
  methods: {
    setGuesToken() {
      let token = localStorage.getItem("token", "");
  
      if (token === null || token === "") {
        axios
          .get(`${window.location.origin}/api_tokens/new.json`)
          .then((response) => {
            if (this.isClassBooking) {
              localStorage.setItem("class_booking", JSON.stringify(true))
            }
            localStorage.setItem("guest_token", response.data.jwt);
          });
      }
      else {
        if (this.isClassBooking){
          localStorage.setItem("class_booking", JSON.stringify(true))
        }
        localStorage.setItem("guest_token", token);
      }
    },
    signInUrl(){
      return `${window.location.origin}/users/sign_in`
    },
    nextStep() {
      let params = {
        user: {
          phone: this.formattedNumber
        }
      }
      this.isLoading = true
      this.$axios.put('/v3/users/profile', params)
        .then(response => {
          this.$emit('updateStep', { next_step: 'fill_password' })
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    },
    back() {
      this.$emit('updateStep', { next_step: 'fill_email' })
    },
    validatePhone(data) {
      this.isValid = data.isValid
      this.formattedNumber = data.formattedNumber
    }
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <div class="complete-signup-header__title">
      What is your
      <span class='complete-signup-header__primary_title'>phone number?</span>
    </div>

    <p class="mt-4 mb-4">
      Provide your contact number so the artist can reach out to you once the booking is confirmed
    </p>

    <div class="new-booking__content">
      <VuePhoneNumberInput
        v-model="phone"
        :required="true"
        class="forms__item-100 order-capture-input"
        v-bind:class="{ 'error': error}"
        :default-country-code="countryCode"
        color="#FE9554"
        :no-validator-state="true"
        error-color="#EF5151"
        @update="validatePhone"
      />
      <span v-if="error" class="error-message">{{ error? error.toString() : null }}</span>

      <div class="auth-form-content text-start mb-4">
        Already a member? <a @click="setGuesToken()" :href="signInUrl()">Log in</a>
      </div>

      <div class="mt-5">
        <div class="back-next">
          <button
            class="settings-forms__btn btn secondary fluid"
            @click="back()">
            Back
          </button>
          <button
            class="settings-forms__btn btn primary fluid"
            :disabled="!phone || !this.isValid"
            @click="nextStep()">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
