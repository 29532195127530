<script>
import moment from 'moment'

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import minusIcon from '../../../images/minus.svg'
import addIcon from '../../../images/plus.svg'

export default {
  components:{
    Loading,
    VueTimepicker
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      moment: moment,
      minusIcon: minusIcon,
      addIcon: addIcon,
      isLoading: false,
      booking_dates: [],
      current_index: 0,
      art_type: {},
      art_types: [],
      error: null,
    }
  },
  mounted() {
    this.isLoading = true
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        if (response.data.booking_dates.length <= 0) {
          this.$emit('updateStep', 'select_date')
        }
        this.booking_dates = response.data.booking_dates.map(booking_date => {
          let art_type_dates = {}
          response.data.booking_art_types.map(art_type => {
            let date_index = art_type.booking_art_type_dates.findIndex(booking_art_type_date => booking_art_type_date.booking_date_id === booking_date.id)

              if (date_index >= 0) {
                art_type_dates[art_type.slug] = art_type.booking_art_type_dates[date_index]
                let time = new Date(art_type_dates[art_type.slug].start_time)
                // const [hours, minutes] = art_type_dates[art_type.slug].start_time.split(':')
                let hours = (time.getUTCHours() < 10)?("0"+time.getUTCHours()):time.getUTCHours()
                let minutes = (time.getUTCMinutes() < 10)?("0"+time.getUTCMinutes()):time.getUTCMinutes()
                const ampm = hours > 12 ? "PM" : "AM"
                let timeFormat = ""
                if (ampm=="PM") {
                  timeFormat = moment(`${hours}:${minutes}:${ampm}`, ["h:mm A"]).format("HH:mm")
                } else {
                  timeFormat = moment(`${hours}:${minutes}`, ["HH:mm"]).format("hh:mm")
                }
                art_type_dates[art_type.slug].start_time = hours ? timeFormat : "12:00"
                art_type_dates[art_type.slug].show = false
                art_type_dates[art_type.slug].closeOnClick = false
                art_type_dates[art_type.slug].timePeriod = time.getUTCHours() > 12 ? 1 : 0 // addition
              } else {
                art_type_dates[art_type.slug] = {
                  id: null,
                  booking_date_id: booking_date.id,
                  booking_art_type_id: art_type.id,
                  start_time: "00:00",
                  non_performance: false,
                  show: false,
                  closeOnClick: true
                }
              }
          })
          return {
            date: booking_date.date,
            id: booking_date.id,
            display_date: booking_date.display_date,
            art_type_dates: art_type_dates
          }
        })
        this.art_types = response.data.booking_art_types
        this.art_type = response.data.booking_art_types[0]
        this.current_index = 0
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })
  },
  computed: {
    disableNextBtn() {
      return this.booking_dates.some(
        date => !this.isValidDate(date)
        && date.art_type_dates[this.art_type.slug].non_performance
        || (
          (date.art_type_dates[this.art_type.slug].start_time < new Date().toTimeString().slice(0,5)
          && date.date == new Date().toJSON().slice(0,10))
        || date.art_type_dates[this.art_type.slug].start_time ==''))
    },
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  methods: {
    dayPeriod(selected_time) {
      if (selected_time === null) return undefined
      return selected_time.split(':')[0] > 12 ? 1 : 0
    },
    updateDayPeriod(date, ampm) {
      const [hours, minutes] = date.art_type_dates[this.art_type.slug].start_time.split(':')
      let timeFormat = '' 
      if (ampm=="PM") {
        timeFormat = moment(`${hours}:${minutes}:${ampm}`, ["h:mm A"]).format("HH:mm")
      } else {
        timeFormat = moment(`${hours}:${minutes}`, ["HH:mm"]).format("hh:mm")
      }
      date.art_type_dates[this.art_type.slug].start_time = hours ? timeFormat : "12:00"
      date.art_type_dates[this.art_type.slug].timePeriod = ampm == "AM" ? 0 : 1
    },
    setTime (value, date) {
      const ampm = date.art_type_dates[this.art_type.slug].timePeriod == 0 ? "AM" : "PM"
      const [hours, minutes] = value.split(':');
      let timeFormat = ''
      if (ampm=="PM") {
        timeFormat = moment(`${hours}:${minutes}:${ampm}`, ["h:mm A"]).format("HH:mm")
      } else {
        timeFormat = moment(`${hours}:${minutes}`, ["HH:mm"]).format("hh:mm")
      }
      date.art_type_dates[this.art_type.slug].start_time = hours ? timeFormat : "12:00"
    },
    bookingParams() {
      return {
        booking: {
          booking_dates_attributes: this.booking_dates.map(date => {
            return {
              id: date.id,
              date: date.date,
              booking_art_type_dates_attributes: this.art_types.map(art_type => {
                return {
                  id: date.art_type_dates[art_type.slug].id,
                  booking_date_id: date.art_type_dates[art_type.slug].booking_date_id,
                  booking_art_type_id: date.art_type_dates[art_type.slug].booking_art_type_id,
                  start_time: new Date(`${date.date.replace(/-/g, "/")} ${date.art_type_dates[art_type.slug].start_time} UTC`).toGMTString(),
                  non_performance: date.art_type_dates[art_type.slug].non_performance
                }
              })
            }
          })
        }
      }
    },
    nextStep() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_duration' })
    },
    nextArtType() {
      let old_index = this.current_index
      this.current_index = old_index + 1
      this.art_type = this.art_types[old_index + 1]
    },
    previousArtType() {
      let old_index = this.current_index
      this.current_index = old_index - 1
      this.art_type = this.art_types[old_index - 1]
    },
    back() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_location' })
    },
    applyAll(date) {
      this.booking_dates.forEach(booking_date => {
        booking_date.art_type_dates[this.art_type.slug].start_time = date.art_type_dates[this.art_type.slug].start_time
      })
    },
    isValidDate(date) {
      const day = new Date(`${date.date.replace(/-/g, "/")} ${date.art_type_dates[this.art_type.slug].start_time} UTC`)
      return day instanceof Date && !isNaN(day) && date.art_type_dates[this.art_type.slug].start_time;
    },
    focusInput(date, slug) {
      if (date.art_type_dates[slug].closeOnClick) {
        setTimeout(() => {
          date.art_type_dates[slug].closeOnClick = false
          date.art_type_dates[slug].show = true
        }, 200)
      } else {
        date.art_type_dates[slug].show = true
      }
    },
    clickOutside(event, date, art_type){
      date.art_type_dates[art_type.slug].show = event.target.id === `input-${date.id}`
    }
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <div class="complete-signup-header__title">
      <span class='complete-signup-header__primary_title'>What time</span> should the
      {{ art_type.name }} start?
    </div>

    <p class="mt-4 mb-5">
      Let artists know exactly when their performance is expected to start
    </p>

    <div class="new-booking__content">
      <p class="mb-3">Select performance timings</p>
      <div class="event_date_time_form">
        <v-app class="w-100">
          <div v-for="(date, index) in booking_dates" :key="date.id" class="date-time-item">
            <p class="col-3 event-date">{{ moment(date.date).format('DD/MM/YYYY') }}</p>
            <div class="col-7 p-0">
              <input
                v-if="date.art_type_dates[art_type.slug].non_performance"
                disabled
                class="mob_time_selector"
                placeholder="No performance on this date"/>
              <div class="mob_time_selector" v-else>
                <v-menu
                  :ref="`menu-${date.id}`"
                  v-model="date.art_type_dates[art_type.slug].show"
                  :close-on-content-click="false"
                  :open-on-click="date.art_type_dates[art_type.slug].closeOnClick"
                  :close-on-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y>
                  <template v-slot:activator="{ on }">
                    <div
                      v-if="!date.art_type_dates[art_type.slug].start_time"
                      :id="`input-${date.id}`"
                      class="input mb-0 cursor-pointer"
                      v-on="on"
                      v-on:click="focusInput(date,art_type.slug)">
                      -- : --
                    </div>
                    <div
                      v-else
                      class="input event-time mx-0 cursor-pointer"
                      v-on:click="focusInput(date,art_type.slug)">
                      {{ date.art_type_dates[art_type.slug].start_time.split(':')[0] % 12 }}:{{ date.art_type_dates[art_type.slug].start_time.split(':')[1] }}
                    </div>
                    <v-btn-toggle
                      mandatory v-model="date.art_type_dates[art_type.slug].timePeriod">
                      <v-btn outlined small @click="updateDayPeriod(date, 'AM')">
                        AM
                      </v-btn>
                      <v-btn outlined small @click="updateDayPeriod(date, 'PM')">
                        PM
                      </v-btn>
                    </v-btn-toggle>
                  </template>
                  <v-time-picker
                    v-if="date.art_type_dates[art_type.slug].show"
                    v-model="date.art_type_dates[art_type.slug].start_time"
                    full-width
                    color="#ff8141"
                    no-title
                    v-click-outside="(event) => {clickOutside(event, date, art_type)}"
                  ></v-time-picker>
                </v-menu>
              </div>

              <input
                v-if="date.art_type_dates[art_type.slug].non_performance"
                class="web_time_selector input py-0 px-2 mb-0"
                disabled
                placeholder="No performance on this date"/>
              <div class="web_time_selector" v-else>
                <input
                  type="time"
                  :id="`input-${date.id}`"
                  class="time-input cursor-pointer"
                  v-model="date.art_type_dates[art_type.slug].start_time"
                  @input="setTime($event.target.value, date)"/>
                <v-btn-toggle mandatory v-model="date.art_type_dates[art_type.slug].timePeriod">
                  <v-btn outlined small @click="updateDayPeriod(date, 'AM')">
                    AM
                  </v-btn>
                  <v-btn outlined small @click="updateDayPeriod(date, 'PM')">
                    PM
                  </v-btn>
                </v-btn-toggle>
                <button class="new-booking__apply-to-all"
                  :disabled="!date.art_type_dates[art_type.slug].start_time"
                  @click="applyAll(date)"
                >Apply to all</button>
              </div>
            </div>
            <div class="d-flex justify-content-end col-2 p-0">
              <button @click="date.art_type_dates[art_type.slug].non_performance = true"
                v-show="!date.art_type_dates[art_type.slug].non_performance"
                class="px-0">
                <font-awesome-icon :icon="['fa', 'fa-minus-square']" size="xl" style="color:#ff8141;" />
              </button>
              <button @click="date.art_type_dates[art_type.slug].non_performance = false"
                v-show="date.art_type_dates[art_type.slug].non_performance"
                class="px-0">
                <font-awesome-icon :icon="['fa', 'fa-plus-square']" size="xl" style="color:#ff8141;" />
              </button>
            </div>
          </div>
        </v-app>
      </div>

      <div class="mt-5">
        <div class="back-next">
          <button
            v-if="current_index > 0"
            class="settings-forms__btn btn secondary fluid"
            @click="previousArtType()">
            Back
          </button>
          <button
            v-if="current_index === 0"
            class="settings-forms__btn btn secondary fluid"
            @click="back()">
            Back
          </button>
          <button
            v-if="current_index < art_types.length - 1"
            class="settings-forms__btn btn primary fluid"
            :disabled="disableNextBtn"
            @click="nextArtType()">
            Next
          </button>
          <button
            v-if="current_index === art_types.length - 1"
            class="settings-forms__btn btn primary fluid"
            :disabled="disableNextBtn"
            @click="nextStep()">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
