<script>
  import facebookImage from '../../images/facebook.svg'
  import googleImage from '../../images/google.svg'

  export default {
    props:{
      show: Boolean,
      default: true
      },
    data: function () {
      return {
        url: null,
        apiUrl: null,
        token: null,
        facebookImage: facebookImage,
        googleImage: googleImage
      }
    },
    mounted() {
      this.$nextTick(function() {
        let origin = window.location.origin.split('//')
        this.apiUrl = `${origin[0]}//api.${origin[1]}`
        this.url = location.origin
        this.token = localStorage.getItem('token')
      })
    },
  }

</script>

<template>
  <div class="socials">
    <a v-if='show' :href="'/auth/facebook?redirect_url='+url+'/users/sign_up'  + '&token=' + token" class="socials-button facebook">
      <span class="socials-button__label">
        <img :src="facebookImage" />
      </span>
    </a>
    <!-- <a :href="'/auth/google?redirect_url='+url+'/users/sign_up' + '&token=' + token" class="socials-button google">
      <span class="socials-button__label">
        <img :src="googleImage" />
      </span>
    </a> -->
  </div>
</template>
